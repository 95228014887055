// import $ from 'jquery';
// import Swiper from 'swiper';
// // import 'swiper/css';
// import '../../../libs/swiper_slider/swiper.scss';
//
// $(document).ready(function () {
//
//     new Swiper(".swiperVideoReviews", {
//         slidesPerView: 3,
//         spaceBetween: 10,
//         cssMode: true,
//         pagination: {
//             el: ".swiper-pagination",
//             clickable: true,
//         },
//         navigation: {
//             nextEl: '.swiper-button-next',
//             prevEl: '.swiper-button-prev',
//         },
//
//
//         // watchSlidesVisibility: true,
//         // breakpoints: {
//         //     "@0.00": {
//         //         slidesPerView: 1,
//         //         spaceBetween: 10,
//         //     },
//         //     "@0.75": {
//         //         slidesPerView: 2,
//         //         spaceBetween: 20,
//         //     },
//         //     "@1.00": {
//         //         slidesPerView: 3,
//         //         spaceBetween: 40,
//         //     },
//         //     "@1.50": {
//         //         slidesPerView: 4,
//         //         spaceBetween: 50,
//         //     },
//         // },
//     })
//     // new Swiper('.swiperVideoReviews', {
//     //     effect: 'coverflow',
//     //     grabCursor: true,
//     //     centeredSlides: true,
//     //     slidesPerView: 1,
//     //     loop: true,
//     //     // Disable preloading of all images
//     //     preloadImages: false,
//     //     // Enable lazy loading
//     //     lazy: {
//     //         loadPrevNext: true,//загрузка нескольких слайдов влево и вправо
//     //         loadPrevNextAmount: 3//Количество слайдов для предзагрузки
//     //     },
//     //     watchSlidesVisibility: true,
//     //     coverflowEffect: {
//     //         rotate: 60,
//     //         stretch: 0,
//     //         depth: 100,
//     //         modifier: 1,
//     //         slideShadows: true,
//     //     },
//     //     pagination: {
//     //         el: '.our_works .swiper-pagination',
//     //     },
//     //     // Navigation arrows
//     //     navigation: {
//     //         nextEl: '.our_works .swiper-button-next',
//     //         prevEl: '.our_works .swiper-button-prev',
//     //     },
//     //     breakpoints: {
//     //         // when window width is >= 320px
//     //         320: {
//     //             slidesPerView: 1
//     //         },
//     //         // when window width is >= 480px
//     //         480: {
//     //             slidesPerView: 1
//     //         },
//     //         // when window width is >= 640px
//     //         640: {
//     //             slidesPerView: 2,
//     //         },
//     //         // when window width is >= 640px
//     //         768: {
//     //             slidesPerView: 3,
//     //         }
//     //     }
//     // });
// });
