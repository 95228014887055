import $ from "jquery";

$(document).ready(function () {

    // ПопАп перезвон
    $('#submitperezvon').on('click', function () {
        const phone = $("#phoneperezvon").val();
        if(phone.length > 7) {
            if (window.ComagicWidget) {
                let phone_client = phone.replace(/\D+/g, "");
                var t = +new Date() + 10000;

                // var settings = {
                //     "url": "https://api.qrenta.ru/sitephone/code_perezvon?phone=78004&site=4&name=Forma&phone_client=" + phone_client,
                //     "method": "GET",
                //     "timeout": 0,
                //     "headers": {
                //         "x-api-key": "de78fc74-4c5b-4412-aa0a-ffefbfb6d0db"
                //     },
                // };
                // $.ajax(settings).done(function (response) {
                //     var id_ploshadki = "205861"; // По дефолту ставим 205861, если код не получен по какой либо причине
                //     if (response['success']) {
                //         id_ploshadki = response['data']['code_perezvon'];
                //     }
                //     console.log(phone_client);
                //     console.log(id_ploshadki);
                //     ComagicWidget.sitePhoneCall({
                //         phone: phone_client,
                //         group_id: id_ploshadki,
                //         delayed_call_time: t.toString()
                //     });
                // });
                var id_ploshadki = "205861"; // CEV
                ComagicWidget.sitePhoneCall({phone: phone_client, group_id: id_ploshadki, delayed_call_time: t.toString()});
                sendDataToServer('sevastopol', phone_client, 'forma', $("#name").val());
            }
        }
    });

    // AnyQuestion перезвон
    $('#submitanyquestionperezvon').on('click', function () {
        const phone = $("#phoneanyquestionperezvon").val();
        if(phone.length > 7) {
            if (window.ComagicWidget) {
                let phone_client = phone.replace(/\D+/g, "");
                var t = +new Date() + 10000;
                // console.log('tut2');
                // var settings = {
                //     "url": "https://api.qrenta.ru/sitephone/code_perezvon?phone=78004&site=4&name=Forma&phone_client=" + phone_client,
                //     "method": "GET",
                //     "timeout": 0,
                //     "headers": {
                //         "x-api-key": "de78fc74-4c5b-4412-aa0a-ffefbfb6d0db"
                //     },
                // };
                // $.ajax(settings).done(function (response) {
                //     var id_ploshadki = "205861"; // По дефолту ставим 205861, если код не получен по какой либо причине
                //     if (response['success']) {
                //         id_ploshadki = response['data']['code_perezvon'];
                //     }
                //     console.log(phone_client);
                //     console.log(id_ploshadki);
                //     ComagicWidget.sitePhoneCall({
                //         phone: phone_client,
                //         group_id: id_ploshadki,
                //         delayed_call_time: t.toString()
                //     });
                // });
                var id_ploshadki = "205861"; // CEV
                ComagicWidget.sitePhoneCall({phone: phone_client, group_id: id_ploshadki, delayed_call_time: t.toString()});
                sendDataToServer('sevastopol', phone_client, 'forma', $("#name").val());
            }
        }
    });


})
