/* Map Price */

const mapPrice = document.querySelector('.map-price');
let mapPriceItem = document.querySelectorAll('.map-price__item');


function switchInfoMap() {
    document.querySelectorAll('.map-price__item--active').forEach(function (el) {
        el.classList.remove('map-price__item--active');
    });

    let clone = mapPriceItem[0].cloneNode(true);
    clone.classList.remove('map-price__item');
    clone.classList.add('map-price__clone');
    let cloneYes = Boolean(document.querySelector('.map-price > .map-price__clone'));
    if (cloneYes == true) {
        document.querySelector('.map-price > .map-price__clone').remove();
    }
    mapPrice.append(clone);

    mapPriceItem[0].classList.add('map-price__item--active');
}

if (mapPriceItem[0]) {
    switchInfoMap();
}


mapPriceItem.forEach(function (item) {
    item.addEventListener('click', () => {

        document.querySelectorAll('.map-price__item--active').forEach(function (el) {
            el.classList.remove('map-price__item--active');
        });

        let clone = item.cloneNode(true);
        clone.classList.remove('map-price__item');
        clone.classList.add('map-price__clone');
        let cloneYes = Boolean(document.querySelector('.map-price > .map-price__clone'));
        if (cloneYes == true) {
            document.querySelector('.map-price > .map-price__clone').remove();
        }
        mapPrice.append(clone);

        item.classList.add('map-price__item--active');
    })
});
