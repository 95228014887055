document.addEventListener("DOMContentLoaded", () => {

    const swiperPromotion = new Swiper(".promotions-swiper", {
        slidesPerView: "3",
        spaceBetween: 20,
        loop: true,
        navigation: {
            nextEl: '.promotions-swiper__next',
            prevEl: '.promotions-swiper__prev'
        },
        pagination: {
            el: '.promotions-swiper__pagination',
            type: 'bullets',
            clickable: true,
        },
        breakpoints: {
            375: {
                slidesPerView: 1,
                spaceBetween: 50
            },
            801: {
                slidesPerView: 2,
                spaceBetween: 20
            },
            1551: {
                slidesPerView: 3,
                spaceBetween: 20
            }
        }
    });


    /* Load multiple swiper slider */
    /*let promotionItems = document.querySelectorAll('.promotions-swiper__wrapper');

    promotionItems.forEach(function(item, index) {
        let promotion = item.querySelector('.swiper-promotion');

        promotion.classList.add('swiper-promotion-' + index);

    });*/


});




