// import $ from 'jquery';
// import $ from '../libs/jquery-3.7.0.min';
import '../libs/jquery-3.7.0.min';
import '../libs/jquery.maskedinput.min';
import '../libs/jquery.event.move';
import './include/header/_header';
import '../libs/read-more';
import './include/map_price/_map-price';
import './include/video_reviews/_video_reviews';
import './include/price_list/_price_list';
import './include/services/_services';
import './include/work_examples/_work_examples';
import './include/faq/_faq';
import './include/description/_description';
import './include/brands/_brands';
import './include/models/_models';
import './include/popup/popup';
import './include/footer_map/map';
import './include/banner/_banner';
import './include/swiper/_swiper';
import './include/reviews/_reviews';
import './include/promotion/_promotion';



// $(document).ready(function ($) {
//
// 	// Маска для телефона
// 	$('.js-phone').mask( "+7(999) 999-99-99");
// 	$('.js-email').mask("*{1,20}[.*{1,20}][.*{1,20}][.*{1,20}]@*{1,20}[.*{2,6}][.*{1,2}]");
//
// 	$('.seo-box__text-hide').hide();
// 	$('.seo-box__more').on('click', function(){
// 		$(this).toggleClass('is-show');
// 		$(this).prev('.seo-box__text-hide').slideToggle();
// 		$(this).text($(this).text() === 'Свернуть' ? 'Развернуть' : 'Свернуть');
// 	});
//
// 	// Показываем ,бренды v1
// 	/*$('.brands > div').hide();
// 	$('.brands > div').slice(0, 4).show();
// 	$('.brands__more').on('click', function (e) {
// 		e.preventDefault();
// 		$('.brands > div:hidden').slice(0, 2).slideDown();
// 		var elHidden = $('.brands > div:hidden').length;
// 		if (elHidden <= 0) {
// 			$('.brands__more').hide();
// 		}
// 	});*/
//
// 	// Показываем ,бренды v2
// 	$('.brands > div').hide();
// 	$('.brands > div').slice(0, 4).show();
// 	$('.section-brands .hide-all').hide();
// 	$('.section-brands .show-all').on('click', function (e) {
// 		e.preventDefault();
// 		$('.brands > div:hidden').slideDown();
// 		$('.section-brands .hide-all').show();
// 		$('.section-brands .show-all').hide();
// 	});
// 	$('.section-brands .hide-all').on('click', function (e) {
// 		e.preventDefault();
// 		$('.brands > div').slice(4).hide();
// 		$('.section-brands .show-all').show();
// 		$('.section-brands .hide-all').hide();
// 	});
//
// 	//блок марок
// 	if ($('.section-cars .hide-all').on('click') == false) {
//         $('.all-items').hide();
//         $('.hide-all').hide();
//         $('.section-cars .slider-items').show();
//         $('.section-cars .show-all').show();
//     }
//
//     $('.section-cars .show-all').on('click', function (e) {
//         e.preventDefault();
//         $('.section-cars .slider-items').hide();
//         $('.section-cars .show-all').hide();
//         $('.all-items').show();
//         $('.section-cars .hide-all').show();
//     });
//
//     $('.section-cars .hide-all').on('click', function (e) {
//         e.preventDefault();
//         $('.section-cars .slider-items').show();
//         $('.section-cars .show-all').show();
//         $('.all-items').hide();
//         $('.section-cars .hide-all').hide();
//     });
//
//     //блок моделей. скрытие кнопки 'Показать все' под слайдерами,
// 	//где количество отображаемых моделей не превышает общее кол-во моделей
// 	if ($(window).width() >= 1200) {
// 		var showedModelsCount = 4;
// 	} else if ($(window).width() >= 960) {
// 		var showedModelsCount = 3;
// 	} else if ($(window).width() >= 640) {
// 		var showedModelsCount = 2;
// 	} else {
// 		var showedModelsCount = 1;
// 	}
//
// 	var elems = $('#slider-models > li .section-cars');
// 	elems.each(function() {
// 		var children = $(this).find('.uk-slider-items').children();
// 		if (children.length <= showedModelsCount) {
// 			$(this).find('.show-all').hide();
// 		}
// 	});
//
// 	$('.section-cars .show-all').on('click', function (e) {
//         e.preventDefault();
// 		elems.each(function() {
// 			var children = $(this).find('.uk-slider-items').children();
// 			if (children.length <= showedModelsCount) {
// 				$(this).find('.slider-items').show();
// 				$(this).find('.all-items').hide();
// 				$(this).find('.hide-all').hide();
// 			}
// 		});
//       });
//
// 	$('.section-cars .hide-all').on('click', function (e) {
//         e.preventDefault();
// 		elems.each(function() {
// 			var children = $(this).find('.uk-slider-items').children();
// 			if (children.length <= showedModelsCount) {
// 				$(this).find('.show-all').hide();
// 			}
// 		});
//     });
//
//
//
// });


