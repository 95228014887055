/* Popup */

import $ from "jquery";

const phoneMask = document.querySelectorAll('[data-mask="phone"]');
const maskOptions = {
    mask: '+{7}(000) 000-00-00'
};
phoneMask.forEach(el => {
    IMask(el, maskOptions);
});


let popup = document.querySelectorAll('.popup');
popup.forEach(function (item) {
    item.style.display = ""
});

let buttonPopup = document.querySelectorAll('.button-popup');
buttonPopup.forEach(function (item) {
    item.addEventListener('click', () => {
        let popupTitle = document.querySelector('.popup__title')

        console.log('Делаем клик');
        if (item.classList.contains('price-consult')) {
            popupTitle.textContent = 'Получить консультацию:';
        } else if (item.classList.contains('price-cost')) {
            popupTitle.textContent = 'Узнать стоимость:';
        }
        else {
            popupTitle.textContent = 'Запись на сервис:';
        }
        document.querySelector('.popup_contacts').classList.add('popup--active');
        document.querySelector('.popup-overlay').classList.add('popup-overlay--active');
    })
});

let overlay = document.querySelector('.popup-overlay');
overlay.addEventListener('click', () => {
    let popup = document.querySelectorAll('.popup');
    popup.forEach(function (item) {
        item.classList.remove('popup--active');
    });
    overlay.classList.remove('popup-overlay--active');
});

let closeBtn = document.querySelectorAll('.popup__close');
for (let i = 0; i < closeBtn.length; i++) {
    closeBtn[i].addEventListener('click', () => {
        closeBtn[i].closest('.popup').classList.remove('popup--active');
        overlay.classList.remove('popup-overlay--active');
    });

let submitBtn  = document.querySelector('#submitperezvon');
let submitMsg = document.querySelector('.popup__msg');
submitBtn.addEventListener('click', () => {
    console.log('Клик')
   submitMsg.style.zIndex = '100';
   submitMsg.style.visibility = 'visible';
   submitMsg.style.opacity = '1';
});

}