const header = document.querySelector('header');
const headerWrap = document.querySelector('.header__wrap');
let headerInner = document.querySelector('.header__inner');
let headerTop = document.querySelector('.header__top');

// Показываем меню после подгрузки css
header.style.opacity = '1';

window.addEventListener('scroll', function () {
    if (window.scrollY > 100) {
        header.classList.add('header--minify');
    } else {
        header.classList.remove('header--minify');
    }
});


// Меняем верстку в зависимости от размера экрана
function headerModify() {
    let windowWidth = window.innerWidth;

    if (windowWidth <= 680) {
        headerInner.prepend(headerTop);
    } else {
        headerWrap.prepend(headerTop);
    }
}
headerModify();

// Trigger
const menuTrigger = document.querySelector('.menu-trigger');

menuTrigger.addEventListener("click", function () {
    this.classList.toggle('menu-trigger--active');
    header.classList.toggle('header--mobile');
});

// Меняем url пунктов меню
function urlModify() {
    let windowWidth = window.innerWidth;
    let menuMultiple = document.querySelectorAll('.menu__item_multiple > .menu__link');

    if (windowWidth <= 1070) {
        menuMultiple.forEach(function (item) {
            if (item.getAttribute('data-src') == null) {
                let href = item.getAttribute('href');

                item.setAttribute('data-src', href);
                item.setAttribute('href', '#!');
            }

        });
    } else if (windowWidth > 1070) {
        menuMultiple.forEach(function (item) {
            if (item.getAttribute('data-src') != null) {
                let dataSrc = item.getAttribute('data-src');
                item.setAttribute('href', dataSrc);
                item.removeAttribute('data-src');
            }
        });
    }
}
urlModify();

function menuDrop() {
    let windowWidth = window.innerWidth;
    let menuMultiple = document.querySelectorAll('.menu__item_multiple > .menu__link');
    let menuBackButton = document.querySelectorAll('.menu-drop__back');

    if (windowWidth <= 1070) {

        menuMultiple.forEach(function (item) {
            item.addEventListener("click", function () {
                item.closest('.menu__item_multiple').classList.add('menu__item_multiple--active');
            });
        });

        menuBackButton.forEach(function (item) {
            item.addEventListener("click", function () {
                item.closest('.menu__item_multiple').classList.remove('menu__item_multiple--active');
            });
        });

    } else if (windowWidth > 1070) {}
}
menuDrop();


// Location choice
let headerLocation = document.querySelector('.header__location');
headerLocation.addEventListener('click', function (event) {
    const office = this.nextElementSibling;

    this.classList.toggle('header__location--active');

    if (!office.classList.contains('office--active')) {
        office.classList.add('office--active');

    } else {
        office.classList.remove('office--active');
    }
});

const officeItem = document.querySelectorAll('.office__item');
officeItem.forEach(function (item) {

    item.addEventListener("click", function () {

        officeItem.forEach(function (el) {
            el.classList.remove('office__item--active');
        });

        const phoneNumber = this.querySelector('.office__phone').innerText;
        let headerPhoneButton = document.querySelector('.header__phone-button');
        headerPhoneButton.innerText = phoneNumber;
        headerPhoneButton.setAttribute('href' , `tel:+${phoneNumber.replace(/\D/g , '')}`);
        item.classList.add('office__item--active');
    });
});

window.addEventListener('resize', function(event) {
    headerModify();
    urlModify();
    menuDrop();
}, true);
