document.addEventListener("DOMContentLoaded", () => {

    const swiperExample = new Swiper(".example-swiper", {
        slidesPerView: "1",
        spaceBetween: 32,
        loop: false,
        navigation: {
            nextEl: '.example-swiper__next',
            prevEl: '.example-swiper__prev'
        },
        pagination: {
            el: '.example-swiper__pagination',
            type: 'bullets',
            clickable: true,
        },
    });

    /* Load multiple swiper slider */
    let galleryItems = document.querySelectorAll('.example-swiper__gallery');

    galleryItems.forEach(function(item, index) {
        let gallery = item.querySelector('.swiper-gallery');
        let gallerySlave = item.querySelector('.swiper-gallery-slave');

        gallery.classList.add('swiper-gallery-' + index);
        gallerySlave.classList.add('swiper-gallery-slave-' + index);

        galleryInit(index);
    });

    function galleryInit(index) {

        const swiperExampleGallerySlave = new Swiper(".swiper-gallery-slave-" + index, {
            slidesPerView: "4",
            spaceBetween: 20,
            freeMode: true,
            watchSlidesProgress: true,
            allowTouchMove: false,
            breakpoints: {
                1001: {
                    slidesPerView: 4,
                    spaceBetween: 11
                }
            }
        });

        const swiperExampleGallery = new Swiper(".swiper-gallery-" + index, {
            slidesPerView: "1",
            spaceBetween: 20,
            allowTouchMove: false,
            navigation: {
                nextEl: '.swiper-gallery__next',
                prevEl: '.swiper-gallery__prev'
            },
            thumbs: {
                swiper: swiperExampleGallerySlave,
            },
        });
    }

});




