document.addEventListener("DOMContentLoaded", () => {

    const swiperReview = new Swiper(".reviews-swiper", {
        slidesPerView: "3",
        spaceBetween: 20,
        loop: true,
        navigation: {
            nextEl: '.reviews-swiper__next',
            prevEl: '.reviews-swiper__prev'
        },
        pagination: {
            el: '.reviews-swiper__pagination',
            type: 'bullets',
            clickable: true,
        },
        breakpoints: {
            375: {
                slidesPerView: 1,
                spaceBetween: 50
            },
            751: {
                slidesPerView: 2,
                spaceBetween: 20
            },
            1101: {
                slidesPerView: 3,
                spaceBetween: 20
            }
        }
    });


    /* Load multiple swiper slider */
    /*let reviewsItems = document.querySelectorAll('.reviews-swiper__wrapper');

    reviewsItems.forEach(function(item, index) {
        let review = item.querySelector('.swiper-review');

        review.classList.add('swiper-review-' + index);

    });*/


});




