




// $('.description__dropup').on('click', function () {
//     var children_description = $(this).parent().find('.mobile_hide');
//     var button_description = $(this).parent().find('.description__dropup');
//     children_description.each(function(i,v) {
//         if($(v).hasClass('mobile_hide')){
//             // $(v).toggleClass('show_hide')
//             $(v).slideToggle(300)
//         }
//     });
//
//     if($(button_description).text() === 'РАЗВЕРНУТЬ'){
//         $(button_description).text('СВЕРНУТЬ')
//     } else {
//         $(button_description).text('РАЗВЕРНУТЬ')
//     }
//
// });