function wrapElementBanner() {
    let w = document.documentElement.clientWidth;
    const bannerItem = document.querySelector('.banner__item');
    const bannerInfo = document.querySelector('.banner__info');
    const bannerButton = document.querySelector('.banner__button');
    const bannerImage = document.querySelector('.banner__image');
    const bannerParent = document.querySelector('.banner_subservice');


    let bannerButtonBoolean = Boolean(document.querySelector('.banner__info > .banner__button'));
    let bannerImageBoolean = Boolean(document.querySelector('.banner__info > .banner__image'));
    let bannerActionBoolean = Boolean(document.querySelector('.banner__info > .banner__action'));
    let bannerParentBoolean = Boolean(document.querySelector('.banner_subservice > .container'));
    let bannerVacancyBoolean = Boolean(document.querySelector('.banner-vacancy > .container'));
    let bannerOfferBoolean = Boolean(document.querySelector('.banner_offer > .container'));

    if((w <= 760 && bannerActionBoolean == false && bannerParentBoolean == true && bannerVacancyBoolean == false && bannerOfferBoolean == false)
        || (w <= 565 && bannerActionBoolean == false && bannerVacancyBoolean == false && bannerOfferBoolean == false)
        || w <= 840 && bannerOfferBoolean == true && bannerActionBoolean == false) {
        let bannerActionBottom = document.createElement('div');
        bannerActionBottom.classList.add('banner__action');
        bannerInfo.append(bannerActionBottom);
        bannerActionBottom.append(bannerButton, bannerImage);
    } else if (w > 565 && bannerButtonBoolean == false && bannerImageBoolean == false && bannerVacancyBoolean == false) {
        bannerItem.append(bannerImage);
        bannerInfo.append(bannerButton);
        if (bannerActionBoolean == true && bannerVacancyBoolean == false) {
            document.querySelector('.banner__info > .banner__action').remove()
        }
    }
}

if (document.querySelector('.banner__item')) {
    wrapElementBanner();
}

window.addEventListener('resize', function (event) {
    if (document.querySelector('.banner__item')) {
        wrapElementBanner();
    }
}, true);