


// $('.models__body__item__show').on('click', function () {
//     var children_models = $(this).parent().find('.models__body__item');
//     var button_models = $(this).parent().find('.models__body__item__show');
//     console.log(children_models);
//     children_models.each(function(i,v) {
//         if($(v).hasClass('mobile_hide')){
//             // $(v).toggleClass('show_hide')
//             $(v).slideToggle(300)
//         }
//     });
//
//     if($(button_models).text() === 'РАЗВЕРНУТЬ'){
//         $(button_models).text('СВЕРНУТЬ')
//     } else {
//         $(button_models).text('РАЗВЕРНУТЬ')
//     }
//
// });