/*document.addEventListener("DOMContentLoaded", () => {*/

    /* Accordion */

    const toggleCollection = document.querySelectorAll('.accordion__item');

    for (let i = 0; i < toggleCollection.length; i++) {
        toggleCollection[i].addEventListener('click', function (event) {
            let target = event.target;
            const toggleContent = this.querySelector('.accordion__data');
            if (!toggleContent.classList.contains('accordion__data--active')) {
                toggleContent.classList.add('accordion__data--active');
                toggleCollection[i].classList.add('accordion__item--active');
                toggleContent.style.height = 'auto';

                let height = toggleContent.clientHeight + 'px';

                toggleContent.style.height = '0px';

                setTimeout(function () {
                    toggleContent.style.height = height;
                }, 0);

            } else if(target.tagName != 'A') {
                toggleContent.style.height = '0px';
                toggleContent.addEventListener('transitionend', function () {
                    toggleContent.classList.remove('accordion__data--active');
                    toggleCollection[i].classList.remove('accordion__item--active');
                }, {
                    once: true
                });
            }
        });
    }


/*});*/


// //раскрытие прайса
// $('.price_list__body__item').on('click', function (e) {
//
//     // e.preventDefault();
//     // elems.each(function() {
//     var children = $(this).find('.price_list__body__item__list');
//     // children.toggleClass('list_item_hide')
//     children.slideToggle(500);
// });