
$(document).ready(function(){
    var button_services = $('.services__body__show');

    var children_services = $('.services__body__item');
    $('.services__body__show').on('click', function () {

        children_services.each(function(i,v) {
            if($(v).hasClass('mobile_hide')){
                // $(v).toggleClass('show_hide')
                $(v).slideToggle(300)
            }
        });

        if($(button_services).text() === 'Развернуть'){
            $(button_services).text('Свернуть');
            $(button_services).css('color','black');
        } else {
            $(button_services).text('Развернуть');
            $(button_services).css('color','white');
        }

    });
});