// import 'uikit'
// import 'uikit/dist/js/uikit-icons'
// import 'uikit/dist/css/uikit.min.css'

import './blocks/main.sass';
import './blocks/main.js';
import './blocks/include/mango/v3ph_perezvon';
// import './blocks/section/callback/callback-popup';
// import './blocks/include/footer_map/map';
import './blocks/include/footer_map/map.js';
// import './blocks/section/mango/mango';


// import './blocks/include/video_reviews/_video_reviews';
// import './libs/lazy_youtube/lazy_youtube';

import LazyLoad from './libs/lazyload.min'

new LazyLoad({
    elements_selector: ".lazy"
});

// import JQuery from './libs/jquery-3.7.0.min'
//
// new JQuery({
//     elements_selector: ".$"
// });