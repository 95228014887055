let more = Boolean(document.querySelector('.button__more'));

if (more == true) {
    let buttonMore = document.querySelector('.button__more');

    function viewElement(count, section) {
        let listItems = section.querySelectorAll('.drop__item:not(.drop__item--visible)');
        if (listItems.length == 0) {
            let listViewItems = document.querySelectorAll('.drop__item--visible');
            listViewItems.forEach(function (item, index) {
                index > count ? item.classList.remove('drop__item--visible') : false;
            });
        }
        listItems.forEach(function (item, index) {
            index <= count ? item.classList.add('drop__item--visible') : false;
        });
        if (document.querySelectorAll('.drop__item:not(.drop__item--visible)').length == 0) {
            section.querySelector('.button__more').textContent = "Свернуть"
        } else {
            section.querySelector('.button__more').textContent = "Развернуть"
        }
    }

    function loadCount() {
        let w = document.documentElement.clientWidth;
        let video = document.querySelector('.our-works')
        let brands = document.querySelector('.brands')
        let models = document.querySelector('.models')

        if (w > 1000){
            video && viewElement(2, video);
            brands && viewElement(11, brands);
            models && viewElement(11, models);
        } else if (w > 767 && w <= 1000) {
            models && viewElement(11, models);
            brands && viewElement(11, brands);
            video && viewElement(1, video);
        } else if (w <= 767 && w > 700) {
            models && viewElement(7, models);
            brands && viewElement(8, brands);
            video && viewElement(1, video);
        } else if (w > 675 && w <= 700) {
            models && viewElement(7, models);
            brands && viewElement(8, brands);
            video && viewElement(2, video);
        } else if (w <= 675) {
            models && viewElement(5, models);
            brands && viewElement(5, brands);
            video && viewElement(2, video);
        }
    }

    loadCount();

    buttonMore.addEventListener('click', function (event) {
        let section = this.closest('.section');
        let listItems = section.querySelectorAll('.drop__item:not(.drop__item--visible)').length;
        if (listItems === 0) {loadCount()} else {viewElement(listItems, section)}
    });
}

// $('.brands__body__item__show').on('click', function () {
//     var children_brand = $(this).parent().find('.brands__body__item');
//     var button_brand = $(this).parent().find('.brands__body__item__show');
//     children_brand.each(function(i,v) {
//         if($(v).hasClass('mobile_hide')){
//             // $(v).toggleClass('show_hide')
//             $(v).slideToggle(300)
//         }
//     });
//
//     if($(button_brand).text() === 'РАЗВЕРНУТЬ'){
//         $(button_brand).text('СВЕРНУТЬ')
//     } else {
//         $(button_brand).text('РАЗВЕРНУТЬ')
//     }
//
// });