const buttonServices = document.querySelector('#read-more-button');
const limiter = document.querySelector('.limiter');


function paragraphHeightSet() {

    let paragraphHeight = document.querySelectorAll('.limiter p')[0].offsetHeight;

    limiter.style.maxHeight = paragraphHeight + "px";

    buttonServices.addEventListener('click', function (event) {
        limiter.style.maxHeight = '100%';
        limiter.classList.toggle('visible_text');

        if (limiter.classList.contains('visible_text')) {
            buttonServices.textContent = "Свернуть"
        } else {
            limiter.style.maxHeight = paragraphHeight + "px";
            buttonServices.textContent = "Развернуть"
        }
    });
}

if (document.querySelector('.limiter')) {
    paragraphHeightSet();
};

window.addEventListener('resize', function (event) {
    if (document.querySelector('.limiter')) {
        paragraphHeightSet();
    };
}, true);